import { useState, useEffect, useRef } from "react";
import { ReactComponent as PhoneIcon } from "../../../assets/svgs/phone_icon.svg";
import { Typography } from "constants/Typography/Typography";
import { FontFamily } from "constants/Fonts";
import { styled, useTheme } from "styled-components";
import { Sticky } from "react-sticky";
import { IContextState } from "store/Context";
import useTranslation from "common/TranslationHook/useTranslation";
import useScrollPosition from "common/useScrollPosition";
import LanguageSwitcher from "common/TranslationHook/LanguageSwitcher";
import OchsnerLogo from "../../../assets/svgs/Ochsner_DigitalMedicine_Logo_Color.svg";
import "./styles.css";
import TannerLogo from "../../../assets/svgs/TannerHealth_icon.svg";


interface HeaderProps {
  smoothScrolling: () => void;
  state: IContextState;
}

const StyledHeaderContainer = styled.div<{ scrolled: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.theme.format > 0 ? "row" : "column")};
  padding: 14px 0px;
  box-shadow: ${(props) =>
    props.scrolled ? "1px 5px 20px 0px rgba(7, 43, 97, 0.15)" : null};
  transition: box-shadow 0.3s;
`;

const StyledHeaderContent = styled.div`
  background-color: white;
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: var(--max-content-width);
  padding-right: 80px;
  padding-left: ${(props) =>
    props.theme.format > 1
      ? "calc(max((100vw - var(--max-content-width)) / 2, 80px))"
      : null};
`;

const StyledMobileHeaderContainer = styled.div`
  z-index: 1000;
`;

const LogoContainer = styled.img`
  display: flex;
  max-height: 56px;
  object-fit: contain;
`;

const MobileLogoContainer = styled.img`
  box-sizing: border-box;
  max-height: 40px;
  max-width: 100%;
  margin: 16px 20px 0px;
`;

const HeaderRightJustifiedContent = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const MobileCallAndSignUpContainer = styled.div<{ scrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px ${(props) => (props.theme.format == 1 ? "40px" : "20px")};
  box-shadow: ${(props) =>
    props.scrolled ? "1px 5px 20px 0px rgba(7, 43, 97, 0.15)" : null};
  clip-path: inset(0px 0px -30px 0px);
  background-color: white;
`;

const HelplineContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SignUpButton = styled.div<{ scrolled: boolean }>`
  display: flex;
  padding: 8px 18px;
  align-items: flex-start;
  font-weight: 600;
  border-radius: 8px;
  border-color: ${(props) => props.theme.colors.buttonPrimary};
  background: ${(props) => props.theme.colors.buttonPrimary};
  color: ${(props) => props.theme.colors.buttonPrimaryText};
  font-size: 16px;
  font-family: "Montserrat-Medium";
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.colors.buttonPrimaryHover};
    background: ${(props) => props.theme.colors.buttonPrimaryHover};
  }
`;

const StyledTelephoneLink = styled(Typography)`
  transition: all 0.3s ease;
  &:hover {
    color: ${(props) => props.theme.colors.textLink};
  }
`;

const Header = ({ smoothScrolling, state }: HeaderProps) => {
  const scrollPosition = useScrollPosition();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [height, setHeight] = useState(0);
  const theme = useTheme();
  const ref = useRef(null);
  const { t } = useTranslation("header");
  const [width, setWidth] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    handleResize(); // Set initial width on component mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setHasScrolled(offset > 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const isMobile = width && width <= 768;
  if (theme.format > 1)
    return (
      <StyledHeaderContainer scrolled={hasScrolled}>
        <StyledHeaderContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "343px",
            }}
          >
            {localStorage.getItem("whiteLabel") !== "true" && (
              <LogoContainer src={OchsnerLogo} />
            )}

            {localStorage.getItem("utm_source") !== "OCHSNER" &&
              localStorage.getItem("utm_source") !== "GENERAL" &&
              localStorage.getItem("utm_default_theme") !== "true" && (
                <>
                  <div
                    style={{
                      width: "1px",
                      height: "40px",

                      backgroundColor: "#CCC",
                    }}
                  ></div>
                  <LogoContainer src={theme.icon} />
                </>
              )}
          </div>
          <HeaderRightJustifiedContent>
            <HelplineContainer
              href={`tel:${state.helpDeskPhoneNumber}`}
            >
              <PhoneIcon style={{ fill: theme.colors.textLink }} />
              <StyledTelephoneLink
                marginTop="0px"
                mb="0px"
                fontFamily={FontFamily.bold}
                textDecoration="underline"
                color={theme.colors.textLink}
              >
                {t("callUs")}
                {theme.format > 1 && <>
                {state.helpDeskPhoneNumber}
                </>
                }
              </StyledTelephoneLink>
            </HelplineContainer>
            <LanguageSwitcher />
            {scrollPosition > 500 && (
              <SignUpButton scrolled={hasScrolled} onClick={smoothScrolling}>
                {t("signUp")}
              </SignUpButton>
            )}
          </HeaderRightJustifiedContent>
        </StyledHeaderContent>
      </StyledHeaderContainer>
    );

  return (
    <StyledMobileHeaderContainer>
      {localStorage.getItem("whiteLabel") !== "true" && (
              
        <MobileLogoContainer src={OchsnerLogo} />
      )}
      {localStorage.getItem("utm_source") !== "OCHSNER" &&
        localStorage.getItem("utm_source") !== "GENERAL" &&
        localStorage.getItem("utm_default_theme") !== "true" && (
          <>
            <MobileLogoContainer src={theme.icon} />
          </>
        )}

      <Sticky topOffset={56}>
        {({ style }) => (
          <MobileCallAndSignUpContainer
            style={{ ...style, boxSizing: "border-box" }}
            scrolled={true}
          >
            <HelplineContainer
              href={`tel:${state.helpDeskPhoneNumber}`}
            >
              <PhoneIcon style={{ fill: theme.colors.textLink }} />
              <StyledTelephoneLink
                marginTop="0px"
                mb="0px"
                fontFamily={FontFamily.bold}
                textDecoration="underline"
                color={theme.colors.textLink}
              >
                {isMobile ? t("callUsMobile") : t("callUs")}{" "}
                
                
              </StyledTelephoneLink>
            </HelplineContainer>
            <LanguageSwitcher />

            <SignUpButton scrolled={hasScrolled} onClick={smoothScrolling}>
              {t("signUp")}
            </SignUpButton>
          </MobileCallAndSignUpContainer>
        )}
      </Sticky>
    </StyledMobileHeaderContainer>
  );
};

export default Header;
