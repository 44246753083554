import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { useEffect } from "react";
import { useApi } from "store/useApi";
import { StyledLink } from "screens/landingPage/newLandingPageComponents/JoinTheProgram";
import useTranslation from "common/TranslationHook/useTranslation";
import { IContextActions, IContextState } from "store/Context";


interface GeneralErrorProps {
  closeButtonAction: () => void;
  state: IContextState;
}

export const GeneralError = ({ closeButtonAction, state }: GeneralErrorProps) => {
  const { auditTrail } = useApi();
  const { t } = useTranslation("GeneralError");

  useEffect(() => {
    auditTrail("Could not locate Patient");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 140,
      }}
    >
      <Typography>
        {t("generalErrorMessage", {
          supportPhone: (
            <StyledLink
              href={`tel:${state.helpDeskPhoneNumber}`}
              style={{ fontFamily: "Montserrat-Bold" }}
            >
              {state.helpDeskPhoneNumber}
            </StyledLink>
          ),
        })}
      </Typography>

      <BackForwardButton
        closeButtonAction={closeButtonAction}
        backVisible={false}
        frontVisible={false}
        closeButtonVisible={true}
      />
    </div>
  );
};
