import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { useEffect } from "react";
import { useApi } from "store/useApi";
import { StyledLink } from "screens/landingPage/newLandingPageComponents/JoinTheProgram";
import useTranslation from "common/TranslationHook/useTranslation";
import { IContextActions, IContextState, MyProvider } from "store/Context";


interface PatientCapErrorProps {
  closeButtonAction: () => void;
  state: IContextState;

}

export const PatientCapError = ({
  closeButtonAction,
  state,
}: PatientCapErrorProps) => {
  const { t } = useTranslation("PatientCapError");
  const { auditTrail } = useApi();

  useEffect(() => {
    auditTrail("Patient Cap error");
  }, []);

  return (
    <div>
      <div style={{ marginBottom: 78 }}>
        <Typography>{t("patientCapErrorHeader")}</Typography>
        <Typography>
          {t("patientCapWaitlistText")}{" "}
          <StyledLink
            href={`tel:${state.helpDeskPhoneNumber}`}
            style={{ fontFamily: "Montserrat-Bold" }}
          >
            {state.helpDeskPhoneNumber}
          </StyledLink>
          {t("patientCapSubText")}
        </Typography>
      </div>

      <BackForwardButton
        closeButtonAction={closeButtonAction}
        backVisible={false}
        frontVisible={false}
        closeButtonVisible={true}
      />
    </div>
  );
};
