import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import BulletPointsComponent from "common/BulletPointsComponent";
import { FontFamily } from "constants/Fonts";
import { useEffect } from "react";
import { IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { StyledLink } from "screens/landingPage/newLandingPageComponents/JoinTheProgram";
import useTranslation from "common/TranslationHook/useTranslation";

interface FinancialErrorProps {
  backStep: () => void;
  closeButtonAction: () => void;
  state: IContextState;
}

export const FinancialError = ({
  backStep,
  closeButtonAction,
  state,
}: FinancialErrorProps) => {
  const { auditTrail } = useApi();
  const { t } = useTranslation("financialError");

  const bulletPointsData = [
    t("tipsBulletPoints.0"),
    t("tipsBulletPoints.1"),
    t("tipsBulletPoints.2"),
    t("tipsBulletPoints.3"),
  ];

  useEffect(() => {
    auditTrail("Patient financial eligibility error");
  }, []);

  return (
    <div>
      <div style={{ marginBottom: 40 }}>
        <Typography mb="16px">
          {t("financialErrorMessage")}
          <StyledLink
            href={`tel:${state.helpDeskPhoneNumber}`}
            style={{ fontFamily: "Montserrat-Bold" }}
          >
            {state.helpDeskPhoneNumber}
          </StyledLink>{" "}
          {t("financialErrorMessage1")}
        </Typography>
        <Typography fontFamily={FontFamily.bold}>{t("tipsTitle")}</Typography>
        <BulletPointsComponent bulletPoints={bulletPointsData} />
      </div>

      <BackForwardButton
        backAction={backStep}
        closeButtonAction={closeButtonAction}
        backVisible={state.oauth ? false : true}
        frontVisible={false}
        closeButtonVisible={true}
      />
    </div>
  );
};
