import { Typography } from "constants/Typography/Typography";
import "./style.css";
import BackForwardButton from "common/BackForwardButton";
import { Color } from "constants/Colors";
import { useEffect, useState, useContext } from "react";
import CheckboxNew from "common/CheckboxNew";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { ThemeContext } from "styled-components";

import { ReactComponent as BPIcon } from "../assets/svgs/program_bp_icon.svg";
import { ReactComponent as GLIcon } from "../assets/svgs/program_gl_icon.svg";
import { ReactComponent as WMIcon } from "../assets/svgs/program_wm_icon.svg";
import React from "react";
import useTranslation from "common/TranslationHook/useTranslation";

interface ProgramSelectionProps {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  setHBPSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setT2Selected: React.Dispatch<React.SetStateAction<boolean>>;
  setWMSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setVisitNeeded: React.Dispatch<React.SetStateAction<string>>;
  state: IContextState;
  actions: IContextActions;
  setPrevStep: React.Dispatch<React.SetStateAction<number>>;
}

// const HBPBulletPointsData = [
//   "Submit blood pressure readings at home",
//   "Uses a digital blood pressure cuff",
// ];

// const T2BulletPointsData = [
//   "Submit blood sugar readings at home",
//   "Uses a digital glucometer",
// ];

export const ProgramSelection = ({
  backStep,
  setStepCount,
  setHBPSelected,
  setT2Selected,
  setWMSelected,
  setVisitNeeded,
  setPrevStep,
  state,
  actions,
}: ProgramSelectionProps) => {
  const [HBPChecked, setHBPChecked] = useState(false);

  const [HBPDisabled, setHBPDisabled] = useState(false);
  const [HBPChosen, setHBPChosen] = useState(false);
  const [HBPHidden, setHBPHidden] = useState(false);
  const [T2Checked, setT2Checked] = useState(false);
  const [T2Disabled, setT2Disabled] = useState(false);
  const [T2Chosen, setT2Chosen] = useState(false);

  const [T2Hidden, setT2Hidden] = useState(false);
  const [WMChecked, setWMChecked] = useState(false);
  const [WMDisabled, setWMDisabled] = useState(false);
  const [WMHidden, setWMHidden] = useState(false);
  const [programSelected, setProgramSelected] = useState(false);
  const [programsEnabled, setProgramsEnabled] = useState(['']);
  const [programsDisabled, setProgramsDisabled] = useState(['']);
  const [programsHidden, setProgramsHidden] = useState(['']);

  const { t } = useTranslation("ProgramSelection");

  const {
    PatientProgress,
    ProgramBenefitsPost,
    auditTrail,
    dateAndFa,
    VisitNeeded,
  } = useApi();

  useEffect(() => {
    PatientProgress("ProgramChoice").then(() => {});
    setPrevStep(PAGE_TITLES.PROGRAM_SELECTION.id);
  }, []);

  useEffect(() => {
    auditTrail("ProgramChoice");
  }, []);

  

  const themeContext = useContext(ThemeContext)!;

  const handleContinue = () => {
    const HTNChosen = HBPChecked;
    const DIABChosen = T2Checked;
    const WMChosen = WMChecked;
    const currentDate = new Date();
    const lastConsentDate = new Date(state.LastConsentDate);

    const monthsDiff =
      (currentDate.getFullYear() - lastConsentDate.getFullYear()) * 12 +
      (currentDate.getMonth() - lastConsentDate.getMonth());

    if (HBPChecked) {
      setHBPSelected(true);
    } else {
      setHBPSelected(false);
    }
    if (T2Checked) {
      setT2Selected(true);
    } else {
      setT2Selected(false);
    }
    if (WMChecked) {
      setWMSelected(true);
    } else {
      setWMSelected(false);
    }

    actions.setValue("htnChosen", HTNChosen);
    actions.setValue("diabChosen", DIABChosen);
    actions.setValue("wmChosen", WMChosen);

    let programs = [];
    if (HTNChosen) {
      programs.push("Hypertension");
    }
    if (DIABChosen) {
      programs.push("Type2Diabetes");
    }
    if (WMChosen) {
      programs.push("WeightManagement");
    }
    if (!WMChosen || HTNChosen || DIABChosen) {
      VisitNeeded(state.patientId, programs).then((data: any) => {
        actions.setValue("visitNeeded", data.VisitNeeded);
        setVisitNeeded(data.VisitNeeded);
      });
    }

    if (WMChosen) {
      if (state.diabEnrolled) {
        setStepCount(PAGE_TITLES.ERROR_WM_DMII.id);
      } else {
        setStepCount(PAGE_TITLES.HEIGHT_WEIGHT.id);
      }
    } else {
      if (state.LastSponsor !== state.sponsor && monthsDiff >= 6) {
        actions.setValue("needToConsent", true);
      }
      setStepCount(PAGE_TITLES.PROGRAM_COST_CONSENT.id);
    }
  };

  const getDateAndFA = async () => {
    const data = await dateAndFa();
    actions.setValue("LastConsentDate", data.LastConsentDate);
    actions.setValue("LastSponsor", data.Sponsor);
  };

  useEffect(() => {
    let programsDisabledArray = [];
    let programsEnabledArray = [];
    let programsHiddenArray = []
    if(state.htnEnrolled){
      // setProgramsDisabled([...programsDisabled, 'HTN'])
      programsDisabledArray.push("HTN")
      setHBPDisabled(true)
    }
    else if(state.htnEligible){
      // setProgramsEnabled([...programsEnabled, 'HTN'])
      programsEnabledArray.push("HTN")
    }
    else {
      // setProgramsHidden([...programsHidden, 'HTN'])
      programsHiddenArray.push("HTN")
      setHBPHidden(true)
    }

    if(state.diabEnrolled){
      // setProgramsDisabled([...programsDisabled, 'DIAB'])
      programsDisabledArray.push("DIAB")
      setT2Disabled(true)
    }
    else if(state.diabEligible){
      // setProgramsEnabled([...programsEnabled, 'DIAB'])
      programsEnabledArray.push("DIAB")
    }
    else {
      // setProgramsHidden([...programsHidden, 'DIAB'])
      programsHiddenArray.push("DIAB")
      setT2Hidden(true)
    }

    if(state.wmEnrolled){
      // setProgramsDisabled([...programsDisabled, 'WM'])
      programsDisabledArray.push("WM")
      setWMDisabled(true)
    }
    else if(state.wmEligible){
      // setProgramsEnabled([...programsEnabled, 'WM'])
      programsEnabledArray.push("WM")
    }
    else {
      // setProgramsHidden([...programsHidden, 'WM'])
      programsHiddenArray.push("WM")
      setWMHidden(true)
    }
    setProgramsEnabled(programsEnabledArray)
    setProgramsDisabled(programsDisabledArray)
    setProgramsHidden(programsHiddenArray)
    // setHBPDisabled(state.htnEnrolled);
    // setT2Disabled(state.diabEnrolled);
    // setWMDisabled(state.wmEnrolled);
    // setHBPHidden(!state.htnEligible);
    // setT2Hidden(!state.diabEligible);

    // console.log(HBPHidden + " hbp hidden");
    // console.log(state.htnEligible + "hbp eligible");
    // if (!state.wmEnrolled) {
    //   setWMHidden(!state.wmEligible);
    // }
    console.log("programs enabled " + programsEnabled)
    console.log("programs disabled " + programsDisabled)
    console.log("programs hidden " + programsHidden)


    getDateAndFA().then(() => {
      if (state.directEnroll) {
        const currentDate = new Date();
        const lastConsentDate = new Date(state.LastConsentDate);

        const monthsDiff =
          (currentDate.getFullYear() - lastConsentDate.getFullYear()) * 12 +
          (currentDate.getMonth() - lastConsentDate.getMonth());

        if (state.LastSponsor !== state.sponsor && monthsDiff >= 6) {
          actions.setValue("needToConsent", true);
        }
        setStepCount(PAGE_TITLES.PROGRAM_COST_CONSENT.id);
      } else return;
    });
  }, []);

  const T2programSelectionBoxBorder = () => {
    if (T2Disabled) {
      return "1px solid #D0DAE5";
    }
    if (T2Checked) {
      return "2px solid #223D6B";
    } else {
      return "1px solid #112E59";
    }
  };

  const HBPprogramSelectionBoxBorder = () => {
    if (HBPDisabled) {
      return "1px solid #D0DAE5";
    } else if (HBPChecked) {
      return "2px solid #223D6B";
    } else {
      return "1px solid #223D6B";
    }
  };

  const WMSelectionBoxBorder = () => {
    if (WMDisabled) {
      return "1px solid #D0DAE5";
    } else if (WMChecked) {
      return "2px solid #223D6B";
    } else {
      return "1px solid #112E59";
    }
  };

  const handleT2Check = () => {
    if (T2Disabled) {
      return;
    }
    setT2Checked(!T2Checked);
    console.log("T2 checked?" + T2Checked);

    if (!programSelected) {
      if (!T2Checked || HBPChecked || WMChecked) {
        setProgramSelected(true);
      } else {
        setProgramSelected(false);
      }
    }
    if (programSelected) {
      if (HBPChecked || WMChecked) {
      } else {
        setProgramSelected(false);
      }
    }
  };

  const handleHBPCheck = () => {
    if (HBPDisabled) {
      return;
    }
    setHBPChecked(!HBPChecked);
    // console.log("HBP checked?" + HBPChecked);

    if (!programSelected) {
      if (!HBPChecked || T2Checked || WMChecked) {
        setProgramSelected(true);
      } else {
        setProgramSelected(false);
      }
    }
    if (programSelected) {
      if (T2Checked || WMChecked) {
      } else {
        setProgramSelected(false);
      }
    }
  };

  const handleWMCheck = () => {
    if (WMDisabled) {
      return;
    }
    setWMChecked(!WMChecked);
    if (!programSelected) {
      if (!WMChecked || T2Checked || HBPChecked) {
        setProgramSelected(true);
      } else {
        setProgramSelected(false);
      }
    }
    if (programSelected) {
      if (T2Checked || HBPChecked) {
      } else {
        setProgramSelected(false);
      }
    }
  };

  const checkT2Error = () => {
    return false;
  };

  const HBPError = false;
  const WMError = false;
  const T2Error = checkT2Error();

  return (
    <div>
      <Typography fontSize="17px">
        {t("programSelectionDescription")}
      </Typography>
      <Typography style={{ fontSize: "15px" }}>
        {t("programSelectionSubHeader")}
      </Typography>
      <div
        className="programOptionsContainer"
        style={{
          gap: "16px",
          display: "flex",
          alignItems: "flex-start",
          alignSelf: "stretch",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        
      {programsEnabled.includes("WM") && 
      <>
      <label
      className="WeightManagementProgramOption"
      style={{
        display: "flex",
        alignItems: "center",
        gap: "24px",
        alignSelf: "stretch",

        border: WMSelectionBoxBorder(),
        borderRadius: "8px",
        padding: "16px",
        flex: "1 0 0",
        cursor: "pointer",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {React.createElement(WMIcon, {
          style: {
            height: 56,
            width: 56,
            flexShrink: 0,
            fill: WMDisabled
            ? Color.lightGray
              : themeContext.colors.tintSecondary50,
          },
        })}
        <div>
          <Typography
            // color={Color.strongDarkBlue}
            // fontSize="18px"
            // fontWeight={FontWeight.normal}
            // fontFamily="Comfortaa bold"
            style={{
              marginLeft: "16px",
              marginTop: "0px",
              marginBottom: "0px",
            }}
            headingLevel="h6"
          >
            {t("weightManagementProgramOption")}
          </Typography>
          <Typography
                    headingLevel="h6"
                    style={{
                      marginLeft: "16px",
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                    color={themeContext.colors.textSecondary}
                  >
                    {t("weightManagementProgramSubText")}
                  </Typography>
                
            </div>
        </div>
            <CheckboxNew
              checked={WMChecked}
              onChange={handleWMCheck}
              error={WMError}
              borderColor={WMDisabled ? Color.darkGray : undefined}
            />{" "}
        </label>
          </>
        }


      {programsEnabled.includes("HTN") && 
      <>
      <label
              className="HighBloodPressureProgramOption"

              style={{
                display: "flex",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                border: HBPprogramSelectionBoxBorder(),
                borderRadius: "8px",
                padding: "16px",
                flex: "1 0 0",

                cursor: HBPDisabled ? "default" : "pointer",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {React.createElement(BPIcon, {
                  style: {
                    height: 56,
                    width: 56,
                    flexShrink: 0,
                    fill: HBPDisabled
                      ? Color.lightGray
                      : themeContext.colors.tintSecondary50,
                  },
                })}
                <div>
                  <Typography
                    // color={Color.strongDarkBlue}
                    // fontSize="18px"
                    // fontWeight={FontWeight.normal}
                    // fontFamily="Comfortaa bold"
                    style={{
                      marginLeft: "16px",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    headingLevel="h6"
                  >
                    {t("highBloodPressureProgramOption")}
                  </Typography>
                  <Typography
                    headingLevel="h6"
                    style={{
                      marginLeft: "16px",
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                    color={themeContext.colors.textSecondary}
                  >
                    {t("highBloodPressureProgramSubText")}
                  </Typography>
                
              </div>
            </div>
            <CheckboxNew
              checked={HBPChecked}
              onChange={handleHBPCheck}
              error={HBPError}
              borderColor={HBPDisabled ? Color.darkGray : undefined}
            />{" "}
          </label>
      </>
      }

      {programsEnabled.includes("DIAB") && 
      <>
      <label
              className="T2DiabetesProgramOption"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                border: T2programSelectionBoxBorder(),
                borderRadius: "8px",
                padding: "16px",
                flex: "1 0 0",
                cursor: T2Disabled ? "default" : "pointer",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {React.createElement(GLIcon, {
                  style: {
                    height: 56,
                    width: 56,
                    flexShrink: 0,
                    fill: T2Disabled
                      ? Color.lightGray
                      : themeContext.colors.tintSecondary50,
                  },
                })}
                <div>
                  <Typography
                    //  color={Color.strongDarkBlue}
                    //  fontSize="18px"
                    //  fontWeight={FontWeight.normal}
                    //  fontFamily="Comfortaa bold"
                    style={{
                      marginLeft: "16px",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    headingLevel="h6"
                  >
                    {t("type2DiabetesProgramOption")}
                  </Typography>
                  <Typography
                      headingLevel="h6"
                      style={{
                        marginLeft: "16px",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                      color={themeContext.colors.textSecondary}
                    >
                      {t("type2DiabetesProgramSubText")}
                    </Typography>
                </div>
              </div>
              <CheckboxNew
                checked={T2Checked}
                onChange={handleT2Check}
                error={T2Error}
                borderColor={T2Disabled ? Color.darkGray : undefined}
              />{" "}
            </label>
      </>
      }

      {programsDisabled.includes("WM") &&
      <>
      <label
            className="WeightManagementProgramOption"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "24px",
              alignSelf: "stretch",

              border: WMSelectionBoxBorder(),
              borderRadius: "8px",
              padding: "16px",
              flex: "1 0 0",
              cursor: "pointer",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {React.createElement(WMIcon, {
                style: {
                  height: 56,
                  width: 56,
                  flexShrink: 0,
                  fill: WMDisabled
                    ? Color.lightGray
                    : themeContext.colors.tintSecondary50,
                },
              })}
              <div>
                <Typography
                  // color={Color.strongDarkBlue}
                  // fontSize="18px"
                  // fontWeight={FontWeight.normal}
                  // fontFamily="Comfortaa bold"
                  style={{
                    marginLeft: "16px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                  headingLevel="h6"
                >
                  {t("weightManagementProgramOption")}
                </Typography>
                <Typography
                    headingLevel="h6"
                    style={{
                      marginLeft: "16px",
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                  >
                    {t("weightManagementProgramSubTextDisabled")}
                  </Typography>
                  </div>
            </div>
            <CheckboxNew
              checked={WMChecked}
              onChange={handleWMCheck}
              error={WMError}
              borderColor={WMDisabled ? Color.darkGray : undefined}
            />{" "}
          </label>
      </>}

      {programsDisabled.includes("HTN") && 
      <>
      <label
              className="HighBloodPressureProgramOption"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                border: HBPprogramSelectionBoxBorder(),
                borderRadius: "8px",
                padding: "16px",
                flex: "1 0 0",

                cursor: HBPDisabled ? "default" : "pointer",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {React.createElement(BPIcon, {
                  style: {
                    height: 56,
                    width: 56,
                    flexShrink: 0,
                    fill: HBPDisabled
                      ? Color.lightGray
                      : themeContext.colors.tintSecondary50,
                  },
                })}
                <div>
                  <Typography
                    // color={Color.strongDarkBlue}
                    // fontSize="18px"
                    // fontWeight={FontWeight.normal}
                    // fontFamily="Comfortaa bold"
                    style={{
                      marginLeft: "16px",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    headingLevel="h6"
                  >
                    {t("highBloodPressureProgramOption")}
                  </Typography>
                  
                    <Typography
                      headingLevel="h6"
                      style={{
                        marginLeft: "16px",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      {t("highBloodPressureProgramSubTextDisabled")}
                    </Typography>
                 
                </div>
              </div>
              <CheckboxNew
                checked={HBPChecked}
                onChange={handleHBPCheck}
                error={HBPError}
                borderColor={HBPDisabled ? Color.darkGray : undefined}
              />
            </label>
      </>
      }

      {programsDisabled.includes("DIAB") &&
      <>
      <label
              className="T2DiabetesProgramOption"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                border: T2programSelectionBoxBorder(),
                borderRadius: "8px",
                padding: "16px",
                flex: "1 0 0",
                cursor: T2Disabled ? "default" : "pointer",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {React.createElement(GLIcon, {
                  style: {
                    height: 56,
                    width: 56,
                    flexShrink: 0,
                    fill: T2Disabled
                      ? Color.lightGray
                      : themeContext.colors.tintSecondary50,
                  },
                })}
                <div>
                  <Typography
                    //  color={Color.strongDarkBlue}
                    //  fontSize="18px"
                    //  fontWeight={FontWeight.normal}
                    //  fontFamily="Comfortaa bold"
                    style={{
                      marginLeft: "16px",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    headingLevel="h6"
                  >
                    {t("type2DiabetesProgramOption")}
                  </Typography>
                    <Typography
                      headingLevel="h6"
                      style={{
                        marginLeft: "16px",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      {t("type2DiabetesProgramSubTextDisabled")}
                    </Typography>
                  
                </div>
              </div>
              <CheckboxNew
                checked={T2Checked}
                onChange={handleT2Check}
                error={T2Error}
                borderColor={T2Disabled ? Color.darkGray : undefined}
              />{" "}
            </label>
    </>}
      </div>
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={false}
        frontVisible={true}
        disabled={programSelected ? false : true}
      />
    </div>
  );
};
