import React, { useState } from "react";
import states from "./states.json"; // Import the JSON data
import { FontFamily } from "constants/Fonts";
import { Typography } from "constants/Typography/Typography";
import InputField from "./InputField/InputField";
import { styled } from "styled-components";
import { ReactComponent as Chevron } from "../assets/svgs/chevronDown.svg";
import useTranslation from "./TranslationHook/useTranslation";

interface StateSelectProps {
  livingState: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  livingstateError: boolean;
}

const StyledStackedStateField = styled.div`
  display: grid;
  align-items: flex-end;
  > * {
    grid-area: 1/1/1/1;
  }
`;

const StyledChevron = styled(Chevron)`
  fill: ${(props) => props.theme.colors.textPrimary};
`;

const StateSelect = ({
  livingState,
  onChange,
  livingstateError,
}: StateSelectProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation("basicForm");
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsDropdownOpen(false);
    onChange(e);
  };
  return (
    <div className="input-field-container">
      {/* <Typography fontFamily={FontFamily.medium}>State</Typography> */}

      <StyledStackedStateField>
        <InputField
          label={t("stateLabel")}
          name="State"
          error={livingstateError}
          value={livingState ?? "Select a State"}
          onChange={() => {}}
        />
        <div
          style={{
            width: "calc(100% - 16px)",
            marginBottom: livingstateError ? "42px" : "16px",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <StyledChevron />
        </div>
        <select
          className={`input-field  ${
            isDropdownOpen ? "custom-select-open" : "custom-select"
          }`}
          value={livingState}
          name="LivingState"
          onChange={handleChange}
          onFocus={() => setIsDropdownOpen(true)}
          onBlur={() => setIsDropdownOpen(false)}
          style={{
            width: "100%",
            height: 50,
            opacity: 0,
            borderColor: livingstateError ? "red" : undefined,
          }}
        >
          <option value="" disabled>
            <Typography>Select your state</Typography>
          </option>
          {states.map((state) => (
            <option key={state.abbreviation} value={state.abbreviation}>
              {state.name}
            </option>
          ))}
        </select>
      </StyledStackedStateField>
    </div>
  );
};

export default StateSelect;
