import styled from "styled-components";
import RadioButton from "./RadioButton";

const BoxedRadioButtonContainer = styled.label<{ checked: boolean }>`
  display: flex;
  border-radius: 6px;
  border: 1px solid #13477D;
  margin-top: 16px;
  border: ${(props) => (props.checked ? "2px" : "1px")} solid
    #13477D;
`;

const RadioButtonWrapper = styled.div`
  margin-left: 20px;
  margin-top: 15px;
`;

interface ConfirmIdentityProps {
  value: string;
  checked: boolean;
  onChange: () => void;
  label: string;
}

const BoxedRadioButton = ({
  value,
  checked,
  onChange,
  label,
}: ConfirmIdentityProps) => {
  return (
    <BoxedRadioButtonContainer checked={checked}>
      <RadioButtonWrapper>
        <RadioButton
          id={`radio-${value}`}
          value={value}
          checked={checked}
          onChange={onChange}
          label={label}
        />
      </RadioButtonWrapper>
    </BoxedRadioButtonContainer>
  );
};

export default BoxedRadioButton;
