import { useMyContext } from "./Context";
import makeApiRequest from "./makeApiRequest";

export const useApi = () => {
  const { state } = useMyContext();

  const auditTrail = async (pageName: string) => {
    const sessionId = localStorage.getItem("sessionId");
    const url = `/PageView?sessionId=${sessionId}&pageName=${pageName}`;
    return makeApiRequest({ url, method: "POST" });
  };

  const PatientSession = async (ptId?: string) => {
    const sessionId = localStorage.getItem("sessionId");
    const url = `/PatientSession?sessionId=${sessionId}&ptId=${
      ptId ?? state.patientId
    }`;
    return makeApiRequest({ url, method: "POST" });
  };

  const UtmContent = async (queryString: string) => {
    const url = `/utmcontent?queryString=${queryString}`;
    return makeApiRequest({ url, method: "GET" });
  };
  const UtmParameters = async (queryString: string) => {
    const sessionId = localStorage.getItem("sessionId");
    const encodedQueryString = encodeURIComponent(queryString);
    const url = `/utmparameters?queryString=${encodedQueryString}&sessionId=${sessionId}`;
    return makeApiRequest({ url, method: "POST" });
  };
  const ContactInfo = async (
    name: string,
    phoneNumber: string,
    emailAddress: string,
    queryString: string | null,
  ) => {
    const [firstName, ...lastName] = name.split(" ");
    const params = {
      FirstName: firstName,
      LastName: lastName.join(" "),
      PhoneNumber: phoneNumber,
      EmailAddress: emailAddress,
    };
    const sessionId = localStorage.getItem("sessionId");
    const url = `/contactinfo?sessionId=${sessionId}&queryString=${queryString}`;
    return makeApiRequest({ url, method: "POST", body: params });
  };

  const PatientLookup = async (idType: string | null = null) => {
    const params = {
      FirstName: state.FirstName,
      LastName: state.LastName,
      BirthDate: state.BirthDate,
      StreetAddressOne: state.StreetAddress,
      City: state.City,
      Gender: state.gender,
      State: state.LivingState,
      MobileNumber: state.PhoneNumber,
      SSN: state.Last4SSN,
      EmailAddress: state.EmailAddress,
      MemberId: null,
      MemberIdType: null,
      SponsorId: state.sponsorId,
      ZipCode: state.ZipCode,
    };
    const url = `/PatientLookup${idType ? `?idType=${idType}` : ""}`;
    return makeApiRequest({ url, method: "POST", body: params });
  };

  const PatientPrograms = async (ptId?: string, loggedIn?: boolean) => {
    const url = `/PatientPrograms?ptId=${ptId ?? state.patientId}&loggedIn=${
      loggedIn ?? null
  }${sessionStorage.getItem("newFlow") == "false" ? "&newFlow=false" : ""}`;
    return makeApiRequest({ url, method: "GET" });
  };

  const PatientProgramsPost = async (
    ptId?: string,
    programs?: Array<string>,
  ) => {
    const url = `/PatientPrograms?ptId=${ptId ?? state.patientId}`;
    const params = programs;

    return makeApiRequest({ url, method: "POST", body: params });
  };

  const VisitNeeded = async (ptId?: string, programs?: Array<string>) => {
    const url = `/VisitNeeded?ptId=${ptId ?? state.patientId}`;
    const params = programs;

    return makeApiRequest({ url, method: "POST", body: params });
  };

  const clients = async () => {
    const url = "/clients";
    return makeApiRequest({ url, method: "GET" });
  };
  const clientData = async (clientName: string) => {
    const encodedClientName = encodeURIComponent(clientName.toUpperCase());
    const url = `/clientdata?clientName=${encodedClientName}`;
    return makeApiRequest({ url, method: "GET" });
  };
  const PatientProgress = async (step: string) => {
    const url = `/PatientProgress?ptID=${state.patientId}&step=${step}`;
    return makeApiRequest({ url, method: "POST" });
  };
  const GetMyChartAccount = async () => {
    const url = `/MyChartAccount?ptID=${state.patientId}`;
    return makeApiRequest({ url, method: "GET" });
  };
  const CreateMyChartAccount = async () => {
    const url = `/MyChartAccount?ptID=${state.patientId}&emailaddress=${state.EmailAddress}`;
    return makeApiRequest({ url, method: "POST" });
  };
  const Consent = async (programName: string, HTMLbody: string) => {
    const url = `/consent?ptId=${state.patientId}&programName=${programName}`;
    return makeApiRequest({
      url,
      method: "POST",
      body: HTMLbody,
      headers: { "Content-Type": "text/html" },
    });
  };
  const GetIdentityQuiz = async () => {
    const params = {
      FirstName: state.FirstName,
      LastName: state.LastName,
      BirthDate: state.BirthDate,
      EmailAddress: state.EmailAddress,
    };
    const url = "/IdentityQuiz";
    return makeApiRequest({ url, method: "POST", body: params });
  };
  const VerifyIdentityQuiz = async (
    questionAnswers: any,
    IDMSessionId: any,
    userAccessCode: any,
  ) => {
    const params = { IDMSessionId, questionAnswers, userAccessCode };
    const url = "/IdentityQuiz";
    return makeApiRequest({ url, method: "PUT", body: params });
  };
  const UpdateUserAddress = async () => {
    const params = {
      userAccessCode: state.userAccessCode,
      address: state.alternateAddress,
      city: state.alternateCity,
      state: state.alternateState,
      zipCode: state.alternateZipCode,
    };
    const url = "/api/CreateUserAndConsent";
    return makeApiRequest({ url, method: "PUT", body: params });
  };
  const CreateUserAndConsent = async () => {
    const params = {
      FirstName: state.FirstName,
      LastName: state.LastName,
      BirthDate: state.BirthDate,
      EmailAddress: state.EmailAddress,
    };
    const url = "/CreateUserAndConsent";
    return makeApiRequest({ url, method: "POST", body: params });
  };
  const uploadID = async (userAccessCode: any, idFront: any, idBack: any) => {
    const params = { userAccessCode, idFront, idBack, country: 0 };
    const url = "/uploadID";
    return makeApiRequest({ url, method: "POST", body: params });
  };
  const checkUploadID = async (userAccessCode: any) => {
    const params = { userAccessCode };
    const url = "/checkUploadID";
    return makeApiRequest({ url, method: "POST", body: params });
  };
  const verifyID = async (userAccessCode: any) => {
    const params = { userAccessCode };
    const url = "/verifyID";
    return makeApiRequest({ url, method: "POST", body: params });
  };
  const getTestResult = async (userAccessCode: any) => {
    const params = { userAccessCode };
    const url = "/GetTestResult";
    return makeApiRequest({ url, method: "POST", body: params });
  };
  const DeviceDetail = async ({
    shippingAddress = null,
    businessAddress = null,
    cuffSize = null,
    hasCgm = null,
    phoneType = null,
    noCuffSize = null,
    obar = null,
  }) => {
    const params = {
      ShippingAddress: shippingAddress,
      BusinessAddress: businessAddress,
      CuffSize: cuffSize,
      HasCgm: hasCgm,
      PhoneType: phoneType,
      NoCuffSize: noCuffSize,
      Obar: obar,
    };
    const url = `/DeviceDetail?ptID=${state.patientId}`;
    return makeApiRequest({ url, method: "POST", body: params });
  };

  const QuestionAnswer = async (question: string, answer: string) => {
    const params = {
      question: question,
      answer: answer,
    };
    const url = `/questionanswer?ExternalEptId=${state.patientId}`;
    return makeApiRequest({ url, method: "POST", body: params });
  };

  const AbandonCartEmail = async (
    email: string | null,
    stepNumber: number | null = null,
    emailClickedDate: string | null = null,
    lastName: string | null,
  ) => {
    const url = `/AbandonCartEmail?stepNumber=${stepNumber}&emailClickedDate=${emailClickedDate}&email=${email}&lastName=${lastName}`;
    return makeApiRequest({ url, method: "POST" });
  };
  const UpdatePatientSession = async (id: string) => {
    const sessionId = localStorage.getItem("sessionId");
    const url = `/PatientSession?sessionId=${sessionId}&id=${id}`;
    return makeApiRequest({ url, method: "PUT" });
  };
  const PatientInfo = async (
    id: string | null,
    ptid: string | null,
    email: string | null,
  ) => {
    const url = `/PatientInfo?id=${id}&ptId=${ptid}&email=${email}`;
    return makeApiRequest({ url, method: "GET" });
  };

  const CallToSchedule = async () => {
    const params = {
      FirstName: state.FirstName,
      LastName: state.LastName,
      BirthDate: state.BirthDate,
      MobileNumber: state.PhoneNumber,
      PtId: state.patientId,
    };
    const url = `/CallToSchedule`;
    return makeApiRequest({ url, method: "POST", body: params });
  };

  const ScheduleVisitError = async () => {
    const params = {
      FirstName: state.FirstName,
      LastName: state.LastName,
      BirthDate: state.BirthDate,
      MobileNumber: state.PhoneNumber,
      PtId: state.patientId,
    };
    const url = `/noslots`;
    return makeApiRequest({ url, method: "POST", body: params });
  };

  const GetOpenSlots = async () => {
    const url = `/getopenslots?ptId=${state.patientId}`;
    return makeApiRequest({ url, method: "GET" });
  };

  const GetOpenSlots2 = async () => {
    // const url = `/getopenslots2?ptId=Z3356868`;
    const url = `/getopenslots2?ptId=${state.patientId}`;
    return makeApiRequest({ url, method: "GET" });
  };

  const ScheduleAppointment = async (wmMedicationAppt: boolean | null) => {
    const params = {
      PatientID: state.patientId,
      Date: state.appointmentDate,
      Time: state.appointmentTime,
      Duration: 30,
      Htn: state.htnChosen,
      Diab: state.diabChosen,
      WtMgmt: wmMedicationAppt,
      ConvertTime: false,
    };
    const url = `/ScheduleAppointment`;
    return makeApiRequest({ url, method: "POST", body: params });
  };

  const ScheduleAppointment2 = async (wmMedicationAppt: boolean | null) => {
    const params = {
      PatientID: state.patientId,
      Date: state.appointmentDate,
      Time: state.appointmentTime,
      Htn: state.htnChosen,
      Diab: state.diabChosen,
      WtMgmt: wmMedicationAppt,
      ConvertTime: false,
      Duration: state.appointmentDuration,
      ProviderId: state.providerId,
    };
    const url = `/ScheduleAppointment2`;
    return makeApiRequest({ url, method: "POST", body: params });
  };

  const ProgramBenefitsPost = async (
    HTNChosen: boolean,
    DIABChosen: boolean,
  ) => {
    const params = {
      PatientId: state.patientId,
      ClientName: state.sponsor,
      HTN: HTNChosen,
      DIAB: DIABChosen,
    };
    const url = `/ProgramBenefits`;
    return makeApiRequest({ url, method: "POST", body: params });
  };
  const ProgramBenefitsPut = async () => {
    const params = {
      PatientId: state.patientId,
      HTN: state.htnProgramBenefits,
      DIAB: state.diabProgramBenefits,
      GlucEligible: state.glucEligible,
      CuffEligible: state.cuffEligible,
      VisitNeeded: state.visitNeeded,
    };
    const url = `/ProgramBenefits`;
    return makeApiRequest({ url, method: "PUT", body: params });
  };

  const dateAndFa = async (id?: string) => {
    let url;
    if (id) {
      url = `/dateAndFa?id=${id}`;
    } else {
      url = `/dateAndFa?ptId=${state.patientId}`;
    }
    return makeApiRequest({ url, method: "GET" });
  };
  const addPatient = async (
    firstName: string,
    lastName: string,
    phoneNumber: string,
    emailAddress: string,
  ) => {
    const sessionId = localStorage.getItem("sessionId");

    const params = {
      FirstName: firstName,
      LastName: lastName,
      MobileNumber: phoneNumber,
      EmailAddress: emailAddress,
    };

    const url = `/addPatient?sessionId=${sessionId}`;

    return makeApiRequest({ url, method: "POST", body: params });
  };
  return {
    auditTrail,
    PatientSession,
    UtmContent,
    UtmParameters,
    ContactInfo,
    PatientLookup,
    PatientPrograms,
    clients,
    clientData,
    PatientProgress,
    GetMyChartAccount,
    CreateMyChartAccount,
    Consent,
    GetIdentityQuiz,
    VerifyIdentityQuiz,
    UpdateUserAddress,
    CreateUserAndConsent,
    uploadID,
    checkUploadID,
    verifyID,
    getTestResult,
    DeviceDetail,
    AbandonCartEmail,
    UpdatePatientSession,
    PatientInfo,
    CallToSchedule,
    ScheduleVisitError,
    GetOpenSlots,
    GetOpenSlots2,
    ScheduleAppointment,
    ScheduleAppointment2,
    ProgramBenefitsPost,
    ProgramBenefitsPut,
    QuestionAnswer,
    PatientProgramsPost,
    dateAndFa,
    addPatient,
    VisitNeeded,
  };
};
