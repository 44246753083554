import { useEffect, useState } from "react";
import { Typography } from "constants/Typography/Typography";
import { FontFamily } from "constants/Fonts";
import BackForwardButton from "common/BackForwardButton";
import RadioButton from "common/RadioButton";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { useTheme } from "styled-components";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface WMMedicationProps {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  state: IContextState;
  actions: IContextActions;
  setPrevStep: React.Dispatch<React.SetStateAction<number>>;
  setVisitNeeded: React.Dispatch<React.SetStateAction<string>>;
}

export const WMMedication = ({
  setStepCount,
  nextStep,
  state,
  backStep,
  actions,
  setVisitNeeded,
}: WMMedicationProps) => {
  const [selectedMedications, setSelectedMedications] = useState("");
  const { t } = useTranslation("WMMedication");
  const options = t("options", { returnObjects: true });
  const theme = useTheme();


  const {
    PatientProgress,
    ProgramBenefitsPost,
    auditTrail,
    QuestionAnswer,
    dateAndFa,
    VisitNeeded,
  } = useApi();

  let questionAnswered = selectedMedications !== "";

  useEffect(() => {
    auditTrail("Program Validation");
  }, []);

  const handleContinue = () => {
    auditTrail("Answered Medication Question");
    actions.setValue("wmMedication", selectedMedications);
    console.log("htnchosen medication " + state.htnChosen);
    console.log("diabchosen medication " + state.diabChosen);
    QuestionAnswer("WLMeds", selectedMedications);

    if (selectedMedications === "Yes") {
      let programs = [];
      if (state.htnChosen) {
        programs.push("Hypertension");
      }
      if (state.diabChosen) {
        programs.push("Type2Diabetes");
      }
      if (state.wmChosen) {
        programs.push("WeightManagement");
      }
      VisitNeeded(state.patientId, programs).then((data: any) => {
        actions.setValue("visitNeeded", data.VisitNeeded);
        setVisitNeeded(data.VisitNeeded);
      });
    }

    const HTNChosen = state.htnChosen;
    console.log("HTN CHOSEN " + HTNChosen);
    if (selectedMedications !== "alreadyTaking") {
      if (state.BMI < 25) {
        // if (HTNChosen === true || state.diabChosen === true){
        // console.log("wrong path!!!!")
        setStepCount(PAGE_TITLES.ERROR_WM.id);
      } else {
        // console.log("cprrect path?!")
        setStepCount(PAGE_TITLES.EXCLUSIONARY_CRITERIA.id);
      }
    } else {
      setStepCount(PAGE_TITLES.EXCLUSIONARY_CRITERIA.id);
    }
  };

  return (
    <div>
      <Typography>{t("introText")}</Typography>
      <Typography fontFamily={FontFamily.bold}>{t("question")}</Typography>
      <div className="eligibility-radio-group" style={{ marginBottom: 24 }}>
        {Array.isArray(options) &&
          options.map((option: any) => (
            <RadioButton
              key={option.id}
              id={option.id}
              value={option.id}
              checked={selectedMedications === option.id}
              onChange={() => setSelectedMedications(option.id)}
              label={option.label}
            />
          ))}
      </div>

      {state.diabChosen ? (
        <></>
      ) : (selectedMedications === "Yes" &&
          state.wmLifestyleProgram === true) ||
        (selectedMedications === "alreadyTaking" &&
          state.wmLifestyleProgram === true) ? (
        <div
          style={{
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
            border: `1px solid ${theme.colors.secondary}`,
            background: `${theme.colors.tintSecondary10}`,
            borderRadius: "8px",
          }}
        >
          <Typography>{t("NotDiabChosen")}</Typography>

        </div>
      ) : state.BMI >= 27 &&
        selectedMedications === "Yes" &&
        state.threefourtyB == false ? (
        <div
          style={{
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
            border: `1px solid ${theme.colors.secondary}`,
            background: `${theme.colors.tintSecondary10}`,
            borderRadius: "8px",
          }}
        >
          <Typography>{t("stateMessages.GLP1Message_1.message")}</Typography>
        </div>
      ) : state.BMI >= 27 &&
        selectedMedications === "Yes" &&
        state.threefourtyB == true ? (
        <div
          style={{
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
            border: `1px solid ${theme.colors.secondary}`,
            background: `${theme.colors.tintSecondary10}`,
            borderRadius: "8px",
          }}
        >
          <Typography>{t("stateMessages.GLP1Message_2.message")}</Typography>
        </div>
      ) : state.BMI > 25 && state.BMI < 27 && selectedMedications === "Yes" ? (
        <div
          style={{
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
            border: `1px solid ${theme.colors.secondary}`,
            background: `${theme.colors.tintSecondary10}`,
            borderRadius: "8px",
          }}
        >
          <Typography>{t("stateMessages.GLP1Message_3.message")}</Typography>
        </div>
      ) : selectedMedications == "alreadyTaking" &&
        state.threefourtyB === true ? (
        <div
          style={{
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
            border: `1px solid ${theme.colors.secondary}`,
            background: `${theme.colors.tintSecondary10}`,
            borderRadius: "8px",
          }}
        >
          <Typography>{t("stateMessages.GLP1Message_4.message")}</Typography>
        </div>
      ) : selectedMedications == "alreadyTaking" &&
        state.threefourtyB === false ? (
        <div
          style={{
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
            border: `1px solid ${theme.colors.secondary}`,
            background: `${theme.colors.tintSecondary10}`,
            borderRadius: "8px",
           
          }}
        >
          <Typography>{t("stateMessages.GLP1Message_5.message")}</Typography>
        </div>
      ) : (
        <></>
      )}
      <BackForwardButton
        forwardAction={handleContinue}
        backVisible={true}
        backAction={backStep}
        disabled={!questionAnswered}
      />
    </div>
  );
};
