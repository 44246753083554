import React from "react";
import styled from "styled-components";
import { ReactComponent as Checkmark } from "../assets/svgs/check_logo_new.svg";
import { Typography } from "constants/Typography/Typography";

const CheckboxContainer = styled.label<{ marginTop?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  margin-top: ${(props) => (props.marginTop ? "24px" : "0px")};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

const CheckboxControl = styled.span<{ checked: boolean; borderColor?: string }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.checked ? '#0075A9' : "#D0DAE5"};
  border-radius: 4px;
  border: ${(props) =>
    props.borderColor
      ? undefined
      : props.checked
      ? '#13477D'
      : '1px solid #13477D'};
  transition: all 0.5s;
`;

const CheckboxZStack = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  > * {
    grid-area: 1/1/1/1;
  }
`;

const StyledCheckmark = styled(Checkmark)<{ checked: boolean }>`
  fill: ${(props) => props.theme.colors.textWhite};
  font-weight: 900;
  height: 12px;
  width: 12px;
  position: relative;
  top: 1px;
  opacity: ${(props) => (props.checked ? 1 : 0)};
`;

const CheckboxText = styled(Typography)`
  margin: 0px;
  ${(props) =>
    props.signup &&
    `
  @media (max-width: 768px) {
    width: 100%;
  }
  `}
`;
interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
  id?: string;
  error?: boolean;
  children?: React.ReactNode;
  label?: string;
  color?: string;
  marginTop?: boolean;
  fontWeight?: number;
  signup?: boolean;
  borderColor?: string;
}

const Checkbox = ({
  checked,
  onChange,
  id,
  children,
  label,
  color,
  marginTop,
  fontWeight,
  signup,
  borderColor,
}: CheckboxProps) => {
  return (
    <CheckboxContainer htmlFor={id} marginTop={marginTop}>
      <HiddenCheckbox checked={checked} onChange={onChange} id={id} />
      <CheckboxZStack>
        <CheckboxControl checked={checked} borderColor={borderColor} />
        <StyledCheckmark checked={checked} />
      </CheckboxZStack>
      <CheckboxText color={color} fontWeight={fontWeight} signup={signup}>
        {children ?? label}
      </CheckboxText>
    </CheckboxContainer>
  );
};

export default Checkbox;
