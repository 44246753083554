import { useState, useEffect } from "react";
import { ReactComponent as PhoneIcon } from "../../../assets/svgs/phone_icon.svg";
import { Typography } from "constants/Typography/Typography";
import { FontFamily } from "constants/Fonts";
import { Color } from "constants/Colors";
import "./styles.css";
import styled, { useTheme } from "styled-components";
import LanguageSwitcher from "common/TranslationHook/LanguageSwitcher";
import useTranslation from "common/TranslationHook/useTranslation";
import LinkComponent from "common/LinkComponent ";
import OchsnerLogo from "../../../assets/svgs/Ochsner_DigitalMedicine_Logo_Color.svg";
import TannerLogo from "../../../assets/svgs/TannerHealth_icon.svg";

import { IContextActions, IContextState, useMyContext } from "store/Context";

const StyledHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.theme.format > 0 ? "row" : "column")};
  padding: 14px 0px;
  transition: box-shadow 0.3s;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 8px 0;
  }
`;
const StyledHeaderContent = styled.div`
  background-color: white;
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: var(--max-content-width);
  padding-right: 80px;
  padding-left: ${(props) =>
    props.theme.format > 1
      ? "calc(max((100vw - var(--max-content-width)) / 2, 80px))"
      : "var(--container-padding)"};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const LogoContainerDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const LogoContainer = styled.img`
  max-height: ${(props) => (props.theme.format > 1 ? "56px" : "58px")};
  object-fit: contain;
  box-sizing: border-box;
  max-width: 65%;
  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
    margin-bottom: 28px;
  }
`;

const HelplineContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    margin-left: 0;
  }
`;
const HeaderRightJustifiedContent = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 38px;
  }
`;
const WizardHeader = () => {

  const [width, setWidth] = useState<number | null>(null);
  const theme = useTheme();
  const { t } = useTranslation("header");
  const context = useMyContext();
  const { state, actions } = context;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isMobile = width && width <= 768;
  return (
    <nav className="main-flow-nav">
      <StyledHeaderContainer>
        <StyledHeaderContent>
          <div style={{ display: "flex", alignItems: "center", gap: "20px", width: "343px" }}>
          {localStorage.getItem("whiteLabel") !== "true" && (
              <LogoContainer src={OchsnerLogo} />
            )}

            {localStorage.getItem("utm_source") !== "OCHSNER" &&
              localStorage.getItem("utm_source") !== "GENERAL" &&
              localStorage.getItem("utm_default_theme") !== "true" && (
                <>
                  <div
                    style={{
                      width: "1px",
                      height: "56px",
                      backgroundColor: "#CCC",
                    }}
                  ></div>
                  <LogoContainer src={theme.icon} />
                </>
              )}
          </div>
          <HeaderRightJustifiedContent>
            <HelplineContainer
              href={`tel:${state.helpDeskPhoneNumber}`}
            >
              <PhoneIcon style={{ fill: theme.colors.textLink }} />
              <Typography
                marginTop="0px"
                mb="0px"
                color={theme.colors.textLink}
                fontFamily={FontFamily.bold}
                textDecoration="underline"
              >
                <LinkComponent href={`tel:${state.helpDeskPhoneNumber}`}>
                  {isMobile ? t("callUsMobile") : t("callUs")}{" "}
                  {theme.format > 1 && !isMobile && <>
                    {state.helpDeskPhoneNumber}
                  </>}
                </LinkComponent>
              </Typography>
            </HelplineContainer>
            <LanguageSwitcher />
          </HeaderRightJustifiedContent>
        </StyledHeaderContent>
      </StyledHeaderContainer>
    </nav>
  );
};
export default WizardHeader;
