import React from "react";
import RadioButton from "common/RadioButton";
import PopupComponent from "common/PopupComponent";
import { Typography } from "constants/Typography/Typography";
import infoIcon from "assets/svgs/infoIcon.svg";
import closeIcon from "assets/svgs/close_icon.svg";
import { Color } from "constants/Colors";
import "../../style.css";
import "./style.css";
import {
  GenderTitle,
  InfoImg,
  InfoSection,
  RadioGroup,
  RadioGroupDiv,
  TextStyle,
} from "./styled";
import { useTheme } from "styled-components";
import useTranslation from "common/TranslationHook/useTranslation";

interface GenderSelectProps {
  gender: string;
  genderError: boolean;
  setGender: (gender: string) => void;
}

const GenderSelect: React.FC<GenderSelectProps> = ({
  gender,
  setGender,
  genderError,
}) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation("basicForm");
  const GENDER_OPTIONS = ["male", "female", "other"];

  return (
    <>
      <GenderTitle>{t("genderLabel")}</GenderTitle>
      <RadioGroupDiv>
        <RadioGroup>
          {GENDER_OPTIONS.map((option) => (
            <RadioButton
              key={option}
              id={option}
              value={option}
              checked={gender === option}
              onChange={() => {
                setGender(option);
              }}
              label={t(`genderOptions.${option}`)}
              labelColor={genderError ? theme.colors.alertRed : undefined}
              borderColor={genderError ? theme.colors.alertRed : undefined}
            />
          ))}
        </RadioGroup>
        <InfoSection>
          <InfoImg onClick={() => setShowPopup(true)} />
          <TextStyle
            onClick={() => {
              setShowPopup(true);
            }}
          >
            {t("genderInfoTitle")}
          </TextStyle>
        </InfoSection>
        <PopupComponent
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          content={<Typography>{t("genderInfoText")}</Typography>}
          closeIcon={closeIcon}
        />
      </RadioGroupDiv>
    </>
  );
};

export default GenderSelect;
