import { Color } from "constants/Colors";
import { styled } from "styled-components";

interface LinkComponentProps {
  href: string|any;
  children: React.ReactNode;
}

const StyledLinkComponent = styled.a`
  color: ${(props) => props.theme.colors.textLink};
  transition: color 0.3s ease;
  &:hover {
    color: ${(props) => props.theme.colors.textLink};
    transition: color 0.3s ease;
  }
`;

const LinkComponent = ({ href, children }: LinkComponentProps) => (
  <StyledLinkComponent
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="links-component"
  >
    {children}
  </StyledLinkComponent>
);

export default LinkComponent;
