import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { useEffect } from "react";
import "../style.css";
import { PAGE_TITLES } from "../PageTitles";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { StyledLink } from "screens/landingPage/newLandingPageComponents/JoinTheProgram";
import useTranslation from "common/TranslationHook/useTranslation";

interface ScheduleVisitErrorProps {
  closeButtonAction: () => void;
  setStepCount: (step: number) => void;
  actions: IContextActions;
  state: IContextState
}

export const ScheduleVisitError = ({
  closeButtonAction,
  setStepCount,
  actions,
  state
}: ScheduleVisitErrorProps) => {
  const { auditTrail, ScheduleVisitError } = useApi();
  const { t } = useTranslation("ScheduleVisitError");

  useEffect(() => {
    auditTrail("Schedule Visit Error");
  }, []);

  const handleContinue = () => {
    ScheduleVisitError();
    actions.setValue("scheduleChoice", "Later");
    setStepCount(PAGE_TITLES.CONFIRMATION_PAGE.id);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 140,
      }}
    >
      <Typography>
        {t("scheduleVisitErrorText")}
        <br />
        <br />
        <Typography>
          {t("scheduleVisitErrorSubText", {
            supportPhoneLink: (
              <StyledLink
                href={`tel:${state.helpDeskPhoneNumber}`}
                style={{ fontFamily: "Montserrat-Bold" }}
              >
                {state.helpDeskPhoneNumber}
              </StyledLink>
            ),
          })}
        </Typography>
      </Typography>

      <BackForwardButton
        backAction={closeButtonAction}
        forwardAction={handleContinue}
        backVisible={true}
        frontVisible={true}
        closeButtonVisible={false}
        backText={t("buttonTryAgain")}
      />
    </div>
  );
};
