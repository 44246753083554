import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { WizardScreen } from "screens/wizardscreen/WizardScreen";
import { MyProvider, useMyContext } from "store/Context";
import { ThemeProvider, DefaultTheme } from "styled-components";
import { ViewPortFormat, WhiteLabelGenericTheme, getThemeForUTMSource } from "constants/Themes";
import { CallToActionSharp } from "@mui/icons-material";
import Signup from "./screens/landingPage/Signup";
import "./App.css";

const App: React.FC = () => {
  const [theme, setTheme] = useState<DefaultTheme | null>(null);
  useEffect(() => {
    const updateTheme = () => {
      const getFormat = (): ViewPortFormat => {
        const width = window.innerWidth;
        if (width < 768) {
          return ViewPortFormat.phone;
        }
        if (width < 1024) {
          return ViewPortFormat.tablet;
        }
        return ViewPortFormat.desktop;
      };
      const newFormat = getFormat();
      if (theme?.format === newFormat) {
        return;
      }
      if (theme) {
        setTheme({ ...theme, format: newFormat });
      } else {
        const storedUTMSource = localStorage.getItem("utm_source");
        const urlUTMSource = new URLSearchParams(window.location.search).get(
          "utm_source",
        );
        console.log("urlUTMSource: " + urlUTMSource)
        const getUTMSource = (): string|any => {
          if (!storedUTMSource) {
            console.log("in the !storedutmsource")
            return urlUTMSource ?? "GENERAL";
          } else if (urlUTMSource && storedUTMSource !== urlUTMSource) {
            console.log("in the else if (urlutmsource)")
            return urlUTMSource;
          } else if(urlUTMSource === null){
            if (window.location.hostname === "mydigmed.ochsner.org"){
              return "WHITELABEL"
            }
            else {
              return storedUTMSource;
            }
          } 
          else {
            console.log("in the else")
            return storedUTMSource;
          }
        };
        const source = getUTMSource().toLocaleUpperCase();
        console.log("source:   " + source);
        if (storedUTMSource !== source) {
          localStorage.setItem("utm_source", source);
        }
        let utmTheme = getThemeForUTMSource(source);
        if (utmTheme.name === 0) {
          localStorage.setItem("utm_default_theme", "true");
          console.log("in the utm theme name === 0");
        } else {
          localStorage.setItem("utm_default_theme", "false");
        }
        console.log("utmtheme   " + utmTheme.name);
        if (window.location.hostname === "mydigmed.ochsner.org" && source !== "TANNER") {
          utmTheme = WhiteLabelGenericTheme;
          setTheme({ ...utmTheme, format: newFormat });
        }
        else {
          setTheme({ ...utmTheme, format: newFormat });
        }
      }
    };
    window.addEventListener("resize", updateTheme);
    console.log(window.location.href);
    console.log(window.location.hostname);
    if (window.location.hostname === "mydigmed.ochsner.org") {
      localStorage.setItem("whiteLabel", "true");
    } else {
      localStorage.setItem("whiteLabel", "false");
    }
    if (!theme) {
      updateTheme();
    }
    return () => {
      window.removeEventListener("resize", updateTheme);
    };
  }, []);

  const TestContextConsumer = () => {
    const context = useMyContext();
    console.log("Context in TestContextConsumer:", context);
    return null;
  };

  if (!theme) {
    return <div />;
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <MyProvider>
          <TestContextConsumer />
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/main" element={<WizardScreen />} />
          </Routes>
        </MyProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
