import Ochsnerlogo from "../assets/svgs/Ochsner_DigitalMedicine_Logo_Color.svg";
import DMLogo from "../assets/svgs/DM_logo_rgb.svg";
import OchsnerAlohaLogos from "../assets/images/logo/Ochsner_Aloha_logos.png";
import AlohaLogo from "../assets/svgs/AlohaCare_logo.svg";
import OchsnerShellLogos from "../assets/images/logo/Ochsner_Shell_logos.png";
import ShellLogo from "../assets/svgs/shell_logo.svg";
import OchsnerLHCCLogos from "../assets/images/logo/Ochsner_LHCC_logos.png";
import LHCCLogo from "../assets/svgs/LHCClogo.svg";
import AmeriHealthLogo from "../assets/svgs/amerihealthlogo.svg";
import HumanaLogo from "../assets/svgs/HumanaLogo.svg";
import TannerLogo from "../assets/svgs/TannerHealth_icon.svg";
import GenericWhiteLabelLogo from "../assets/svgs/DM_logo_rgb.svg"


export type Theme = {
  name: ThemeName;
  colors: ThemeColors;
  icon: string;
  secondaryFont: string;
};

export const getThemeForUTMSource = (source?: string): Theme => {
  switch (source) {
    case "SHELL":
      return ShellTheme;
    case "ALOHA CARE":
    case "ALOHA_CARE":
    case "ALOHACARE":
      return AlohaCareTheme;
    case "MARDIGRAS":
      return process.env.REACT_APP_API_URL?.includes("dmportaltest")
        ? MardiGrasTheme
        : OchsnerTheme;
    case "LHCC":
      return LHCCTheme;
    case "AMERIHEALTH":
      return AmeriHealthTheme;
    case "HUMANA":
      return HumanaTheme;
    case "OCHSNER":
      return OchsnerTheme;
    case "TANNER":
      return TannerHealthTheme;
    case "WHITELABEL":
      return WhiteLabelGenericTheme;
    default:
      return OchsnerTheme;
  }
};

export enum ThemeName {
  ochsner,
  alohaCare,
  shell,
  LHCC,
  AmeriHealth,
  Humana,
  TannerHealth,
  WhiteLabelGeneric
}
export class ThemeColors {
  constructor(themeCustomColors: ThemeColorsCustom) {
    this.primary = themeCustomColors.primary;
    this.secondary = themeCustomColors.secondary;
    this.tertiary = themeCustomColors.tertiary;
    this.textPrimary = themeCustomColors.textPrimary;
    this.textLink = themeCustomColors.textLink;
    this.bgLight = themeCustomColors.bgLight;
    this.bgDark = themeCustomColors.bgDark;
    this.buttonPrimary = themeCustomColors.buttonPrimary;
    this.buttonPrimaryHover = themeCustomColors.buttonPrimaryHover;
    this.buttonSecondary = themeCustomColors.buttonSecondary;
    this.buttonPrimaryText = themeCustomColors.buttonPrimaryText;
    this.buttonSecondaryText = themeCustomColors.buttonSecondaryText;
    this.buttonSecondaryHover = themeCustomColors.buttonSecondaryHover;
    this.tintPrimary50 = themeCustomColors.tintPrimary50;
    this.tintPrimary20 = themeCustomColors.tintPrimary20;
    this.tintPrimary10 = themeCustomColors.tintPrimary10;
    this.tintSecondary50 = themeCustomColors.tintSecondary50;
    this.tintSecondary20 = themeCustomColors.tintSecondary20;
    this.tintSecondary10 = themeCustomColors.tintSecondary10;
    this.tintTertiary50 = themeCustomColors.tintTertiary50;
    this.tintTertiary20 = themeCustomColors.tintTertiary20;
    this.tintTertiary10 = themeCustomColors.tintTertiary10;
    this.dividerHeader = themeCustomColors.dividerHeader;
  }

  primary: string;
  secondary: string;
  tertiary: string;
  //text
  textPrimary: string;
  textLink: string;
  textWhite: string = "#FFFFFF";
  textSecondary: string = "#425567";
  //bg
  bgLight: string;
  bgDark: string;
  bgWhite: string = "#FFFFFF";
  //button
  buttonPrimary: string;
  buttonPrimaryHover: string;
  buttonSecondary: string;
  buttonPrimaryText: string;
  buttonSecondaryText: string;
  buttonSecondaryHover?: string;
  //tints
  tintPrimary50: string;
  tintPrimary20: string;
  tintPrimary10: string;
  tintSecondary50: string;
  tintSecondary20: string;
  tintSecondary10: string;
  tintTertiary50: string;
  tintTertiary20: string;
  tintTertiary10: string;
  //alerts
  alertGreen: string = "#6CB33E";
  alertBlue: string = "#0075C9";
  alertOrange: string = "#EBAB21";
  alertRed: string = "#AA182C";
  //divider
  dividerBody: string = "#CCCCCC";
  dividerHeader: string;
  //disabledstates
  disabledFormFieldBG: string = "#F7F7F7";
  disabledFormFieldBorder: string = "#757679";
  disabledButtonBG: string = "#E8E8E8";
}

type ThemeColorsCustom = {
  primary: string;
  secondary: string;
  tertiary: string;
  //text
  textPrimary: string;
  textLink: string;
  //bg
  bgLight: string;
  bgDark: string;
  //button
  buttonPrimary: string;
  buttonPrimaryHover: string;
  buttonSecondary: string;
  buttonPrimaryText: string;
  buttonSecondaryText: string;
  buttonSecondaryHover?: string;
  //tints
  tintPrimary50: string;
  tintPrimary20: string;
  tintPrimary10: string;
  tintSecondary50: string;
  tintSecondary20: string;
  tintSecondary10: string;
  tintTertiary50: string;
  tintTertiary20: string;
  tintTertiary10: string;
  //divider
  dividerHeader: string;
};

export enum ViewPortFormat {
  phone = 0,
  tablet = 1,
  desktop = 2,
}

export const OchsnerTheme: Theme = {
  name: ThemeName.ochsner,
  colors: new ThemeColors({
    primary: "#112E59",
    secondary: "#F0AD00",
    tertiary: "#0075C9",
    // textPrimary: "#112E59",
    textPrimary: "#002D5D",
    textLink: "#0075C9",
    bgLight: "#E5F1FA",
    bgDark: "#223D6B",
    buttonPrimary: "#F0AD00",
    buttonPrimaryHover: "#F8D680",
    buttonSecondary: "#112E59",
    buttonSecondaryHover: "#0075C9",
    buttonPrimaryText: "#101820",
    buttonSecondaryText: "#112E59",
    tintPrimary50: "#89A3BE",
    tintPrimary20: "#D0DAE5",
    tintPrimary10: "#E7EDF2",
    tintSecondary50: "#F8D680",
    tintSecondary20: "#FCEFCC",
    tintSecondary10: "#FDF7E5",
    tintTertiary50: "#80BAE4",
    tintTertiary20: "#CCE3F4",
    tintTertiary10: "#E5F1FA",
    dividerHeader: "#F0AD00",
  }),
  icon: Ochsnerlogo,
  secondaryFont: "Comfortaa",
};

export const ShellTheme: Theme = {
  ...OchsnerTheme,
  name: ThemeName.shell,
  icon: ShellLogo,
};
export const MardiGrasTheme: Theme = {
  name: ThemeName.ochsner,
  colors: new ThemeColors({
    primary: "#85C448",
    secondary: "#85C448",
    tertiary: "#67116D",
    textPrimary: "#7D32AA",
    textLink: "#4B842E",
    bgLight: "#FFF1CC",
    bgDark: "#F8D680",
    buttonPrimary: "#4B842E",
    buttonPrimaryHover: "#386323",
    buttonSecondary: "#0A491F",
    buttonPrimaryText: "#FFFFFF",
    buttonSecondaryText: "#0A491F",
    tintPrimary50: "#C2E1A3",
    tintPrimary20: "#E7F3DA",
    tintPrimary10: "#F3F9ED",
    tintSecondary50: "#C2E1A3",
    tintSecondary20: "#E7F3DA",
    tintSecondary10: "#F3F9ED",
    tintTertiary50: "#B388B6",
    tintTertiary20: "#E1CFE2",
    tintTertiary10: "#F0E7F0",
    dividerHeader: "#4B842E",
  }),
  icon: Ochsnerlogo,
  secondaryFont: "monospace",
};

export const AlohaCareTheme: Theme = {
  name: ThemeName.alohaCare,
  colors: new ThemeColors({
    primary: "#072B61",
    secondary: "#D64129",
    tertiary: "#0075C9",
    textPrimary: "#072B61",
    textLink: "#0075C9",
    bgLight: "#F1F1F2",
    bgDark: "#082B61",
    buttonPrimary: "#D64129",
    buttonPrimaryHover: "#A0311F",
    buttonSecondary: "#072B61",
    buttonPrimaryText: "#FFFFFF",
    buttonSecondaryText: "#072B61",
    tintPrimary50: "#8395B0",
    tintPrimary20: "#CDD5DF",
    tintPrimary10: "#E6EAEF",
    tintSecondary50: "#EBA094",
    tintSecondary20: "#F7D9D4",
    tintSecondary10: "#FBECEA",
    tintTertiary50: "#80BAE4",
    tintTertiary20: "#CCE3F4",
    tintTertiary10: "#E5F1FA",
    dividerHeader: "#D64129",
  }),
  icon: AlohaLogo,
  secondaryFont: "KoHo",
};

export const LHCCTheme: Theme = {
  name: ThemeName.LHCC,
  colors: new ThemeColors({
    primary: "#CC401D",
    secondary: "#F58220",
    tertiary: "#CB187D",
    textPrimary: "#000000",
    textLink: "#CC401D",
    bgLight: "#F5F5F5",
    bgDark: "#58595B",
    buttonPrimary: "#F58220",
    buttonPrimaryHover: "#F7A158",
    buttonSecondary: "#CC401D",
    buttonPrimaryText: "#000000",
    buttonSecondaryText: "#CC401D",
    tintPrimary50: "#E59F8E",
    tintPrimary20: "#F5D9D2",
    tintPrimary10: "#FAECE8",
    tintSecondary50: "#FAC18F",
    tintSecondary20: "#FDE6D2",
    tintSecondary10: "#FEF2E9",
    tintTertiary50: "#E58BBE",
    tintTertiary20: "#F5D1E5",
    tintTertiary10: "#FAE8F2",
    dividerHeader: "#F58220",
  }),
  icon: LHCCLogo,
  secondaryFont: "Montserrat",
};

export const AmeriHealthTheme: Theme = {
  name: ThemeName.AmeriHealth,
  colors: new ThemeColors({
    primary: "#0A2458",
    secondary: "#003DA5",
    tertiary: "#69B3E7",
    textPrimary: "#0A2458",
    textLink: "#003DA5",
    bgLight: "#F2F4F8",
    bgDark: "#0A2458",
    buttonPrimary: "#D22730",
    buttonPrimaryHover: "#9D1D24",
    buttonSecondary: "#003DA5",
    buttonPrimaryText: "#FFFFFF",
    buttonSecondaryText: "#0A2458",
    tintPrimary50: "#8491AB",
    tintPrimary20: "#CED3DE",
    tintPrimary10: "#E6E9EE",
    tintSecondary50: "#809ED2",
    tintSecondary20: "#CCD8ED",
    tintSecondary10: "#E5ECF6",
    tintTertiary50: "#B4D9F3",
    tintTertiary20: "#E1F0FA",
    tintTertiary10: "#F0F7FD",
    dividerHeader: "#D22730",
  }),
  icon: AmeriHealthLogo,
  secondaryFont: "Utopia STD",
};

export const HumanaTheme: Theme = {
  name: ThemeName.Humana,
  colors: new ThemeColors({
    primary: "#85C448",
    secondary: "#85C448",
    tertiary: "#67116D",
    textPrimary: "#3A3B3D",
    textLink: "#4B842E",
    bgLight: "#F8F8F8",
    bgDark: "#01335B",
    buttonPrimary: "#4B842E",
    buttonPrimaryHover: "#386323",
    buttonSecondary: "#0A491F",
    buttonPrimaryText: "#FFFFFF",
    buttonSecondaryText: "#0A491F",
    tintPrimary50: "#C2E1A3",
    tintPrimary20: "#E7F3DA",
    tintPrimary10: "#F3F9ED",
    tintSecondary50: "#C2E1A3",
    tintSecondary20: "#E7F3DA",
    tintSecondary10: "#F3F9ED",
    tintTertiary50: "#B388B6",
    tintTertiary20: "#E1CFE2",
    tintTertiary10: "#F0E7F0",
    dividerHeader: "#4B842E",
  }),
  icon: HumanaLogo,
  secondaryFont: "FS Humana",
};

export const TannerHealthTheme: Theme = {
  name: ThemeName.TannerHealth,
  colors: new ThemeColors({
    primary: "#847770",
    secondary: "#74A333",
    tertiary: "#0075A9",
    textPrimary: "#212721",
    textLink: "#487629",
    bgLight: "#F3F1F1",
    bgDark: "#004B87",
    buttonPrimary: "#487629",
    buttonPrimaryHover: "#2B4718",
    buttonSecondary: "#487629",
    buttonPrimaryText: "#FFFFFF",
    buttonSecondaryText: "#487629",
    tintPrimary50: "#C1BBB7",
    tintPrimary20: "#E6E4E2",
    tintPrimary10: "#F3F1F1",
    tintSecondary50: "#B9D199",
    tintSecondary20: "#E3EDD6",
    tintSecondary10: "#F1F6EB",
    tintTertiary50: "#80BAD4",
    tintTertiary20: "#CCE3EE",
    tintTertiary10: "#CCE3EE",
    dividerHeader: "#A0B0D2",
  }),
  icon: TannerLogo,
  secondaryFont: "Elza",
};

export const WhiteLabelGenericTheme: Theme = {
  name: ThemeName.WhiteLabelGeneric,
  colors: new ThemeColors({
    primary: "#112E59",
    secondary: "#F0AD00",
    tertiary: "#0075C9",
    // textPrimary: "#112E59",
    textPrimary: "#002D5D",
    textLink: "#0075C9",
    bgLight: "#E5F1FA",
    bgDark: "#223D6B",
    buttonPrimary: "#F0AD00",
    buttonPrimaryHover: "#F8D680",
    buttonSecondary: "#112E59",
    buttonSecondaryHover: "#0075C9",
    buttonPrimaryText: "#101820",
    buttonSecondaryText: "#112E59",
    tintPrimary50: "#89A3BE",
    tintPrimary20: "#D0DAE5",
    tintPrimary10: "#E7EDF2",
    tintSecondary50: "#F8D680",
    tintSecondary20: "#FCEFCC",
    tintSecondary10: "#FDF7E5",
    tintTertiary50: "#80BAE4",
    tintTertiary20: "#CCE3F4",
    tintTertiary10: "#E5F1FA",
    dividerHeader: "#F0AD00",
  }),
  icon: GenericWhiteLabelLogo,
  secondaryFont: "Comfortaa",
};