import { Typography } from "constants/Typography/Typography";
import styled from "styled-components";
import ProgramImage from "../../../assets/images/LandingPage/Desktop/join_program_img.png";
import useTranslation from "common/TranslationHook/useTranslation";

const OuterContainer = styled.div`
  z-index: 5;
  display: flex;
  gap: ${(props) => (props.theme.format > 0 ? "40px" : "24px")};
  flex-direction: ${(props) => (props.theme.format > 1 ? "row" : "column")};
  align-content: center;
  max-width: var(--max-content-width);
  padding: var(--content-gap) var(--container-padding);
  margin: auto;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => (props.theme.format > 1 ? "32px" : "24px")};
`;

const ChecklistItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

const ChecklistHeading = styled.div`
  display: flex;
  gap: 16px;
  align-content: center;
  align-items: center;
`;

const StyledBullet = styled(Typography)`
  margin: 0px;
  border: solid 2px;
  border-radius: 44px;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${(props) => props.theme.colors.buttonPrimary};
  font-weight: 600;
  font-size: 27px;
  line-height: 27px;
`;

const StyledProgramImage = styled.img`
  align-items: center;
  max-width: ${(props) =>
    props.theme.format > 1 ? "calc(50% - 20px)" : "100%"};
  object-fit: cover;
  border-radius: 16px;
`;

export const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.textLink};
  text-decoration-line: underline;
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;

interface JoinTheProgramProps {
  smoothScrolling: () => void;
}

const JoinTheProgram = ({ smoothScrolling }: JoinTheProgramProps) => {
  const { t } = useTranslation("jointheprogram");

  return (
    <OuterContainer>
      <TextContainer>
        <Typography headingLevel="h2" marginTop="0px" mb={"0px"}>
          {t("joinTheProgramHeader")}
        </Typography>
        <ChecklistItem>
          <ChecklistHeading>
            <StyledBullet>1</StyledBullet>
            <Typography marginTop="2px" mb="0px" headingLevel="h3">
              {t("checklistItem1Heading")}
            </Typography>
          </ChecklistHeading>
          <Typography marginTop="0px" mb="0px">
          <span style={{ fontFamily: "Montserrat-Bold" }}>
                  {t("checklistItem1TextIntro")}
                </span>{" "}
              {t("checklistItem1Text", {
                signUpPortalLink: (
                  <StyledLink onClick={smoothScrolling}>
                    {t("signUpPortalLink")}
                  </StyledLink>
                ),
              })}
            {" "}
          </Typography>
        </ChecklistItem>
        <ChecklistItem>
          <ChecklistHeading>
            <StyledBullet>2</StyledBullet>
            <Typography marginTop="2px" mb="0px" headingLevel="h3">
              {t("checklistItem2Heading")}
            </Typography>
          </ChecklistHeading>
          <Typography marginTop="0px" mb="0px">
            <span style={{fontFamily: "Montserrat-Bold"}}>
              {t("checklistItem2TextIntro")}
            </span>
            {t("checklistItem2Text")}
          </Typography>
        </ChecklistItem>
        <ChecklistItem>
          <ChecklistHeading>
            <StyledBullet>3</StyledBullet>
            <Typography marginTop="2px" mb="0px" headingLevel="h3">
              {t("checklistItem3Heading")}
            </Typography>
          </ChecklistHeading>
          <Typography marginTop="0px" mb="0px">
            {t("checklistItem3Text")}
            <span style={{fontFamily: "Montserrat-Bold"}}>
              {t("checklistItem3Outro")}
            </span>
          </Typography>
        </ChecklistItem>
      </TextContainer>
      <StyledProgramImage src={ProgramImage} />
    </OuterContainer>
  );
};

export default JoinTheProgram;
