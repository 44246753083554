import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { MainContainer } from "./SignupLaterContainer/styled";
import { useMyContext } from "store/Context";
import "./signup.css";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "screens/wizardscreen/PageTitles";

import Programs from "./newLandingPageComponents/Programs";
import VideoWithCheckItems from "./newLandingPageComponents/VideoWithCheckItems";
import JoinTheProgram from "./newLandingPageComponents/JoinTheProgram";
import CustomerReviewAndMetrics from "./newLandingPageComponents/CustomerReviewAndMetrics";
import FAQ from "./newLandingPageComponents/FAQ";
import MoreInformationForm from "./newLandingPageComponents/MoreInformationForm";
import NewSignupContainer from "./newLandingPageComponents/NewSignupContainer";

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const signupRef = useRef<HTMLDivElement>(null);
  const context = useMyContext();
  if (!context)
    throw new Error("useMyContext must be used within a MyProvider");

  // const [width, setWidth] = useState<number | null>(null);
  const [, setHeight] = useState<number | null>(null);
  const [utmContent, setUtmContent] = useState("GENERAL");
  const [queryString, setQueryString] = useState<string | null>("");
  const apiUrl = process.env.REACT_APP_OAUTH_URL;
  const searchParams = new URLSearchParams(location.search);

  const { state, actions } = context;
  const { auditTrail, UtmContent, UtmParameters } = useApi();

  const generateId = () => {
    let localStorageSSID = localStorage.getItem("sessionId");
    if (!localStorageSSID) {
      localStorageSSID = uuidv4();
      localStorage.setItem("sessionId", localStorageSSID);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("whiteLabel") === "true") {
      actions.setValue("whiteLabel", true);
      if(localStorage.getItem("utm_source") === "TANNER"){
        actions.setValue("helpDeskPhoneNumber", "855-486-3053");
      }
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("language");
    generateId();
    auditTrail("Signup Page");
    UtmContent(searchParams.toString()).then((data) => {
      if (data) {
        setUtmContent(data.Content?.toUpperCase());
        auditTrail("UtmContent " + utmContent);
      }
    });

    setHeight(document.documentElement.clientHeight);
  }, []);

  //Pathway 2
  useEffect(() => {
    const ptId = searchParams.get("ptId");
    const firstName = searchParams.get("firstName");
    const lastName = searchParams.get("lastName");
    const capMet = searchParams.get("capMet")?.toLowerCase();
    const sponsor = searchParams.get("sponsor")?.toLowerCase();
    const queryString = searchParams.get("queryString");
    const directLink = searchParams.get("directLink")?.toLowerCase();
    const errorParam = searchParams.get("error");
    const newFlow = searchParams.get("newFlow")?.toLowerCase();
    console.log(newFlow);
    actions.setValue("newFlow", newFlow);
    if (newFlow == "false") {
      sessionStorage.setItem("newFlow", "false");
    }

    console.log("searchParams ", window.location.href);
    if (directLink) {
      if (directLink === "hypertension") {
        actions.setValue("htnChosen", true);
        actions.setValue("htnProgramBenefits", true);
        actions.setValue("cuffEligible", true);
      } else if (directLink === "type 2 diabetes") {
        actions.setValue("diabChosen", true);
        actions.setValue("diabProgramBenefits", true);
        actions.setValue("glucEligible", true);
      }
      if (ptId && firstName && lastName && capMet && sponsor) {
        actions.setValue("patientId", ptId);
        actions.setValue("FirstName", firstName);
        actions.setValue("LastName", lastName);
        actions.setValue("capmet", capMet);
        actions.setValue("sponsor", sponsor);
        actions.setValue("comingFromSignup", true);
        actions.setValue("oauth", true);
        actions.setValue("hasMyChartAccount", true);
        actions.setValue("directEnroll", true);
        actions.setValue("newFlow", newFlow);
      }
    }
    setQueryString(queryString);
    if (errorParam === "missingRequiredDemographics") {
      actions.setValue("comingFromSignup", true);
      console.log("IN HERE @");
      navigate("/main", {
        state: { stepCount: PAGE_TITLES.ERROR_USERINFO.id },
      });
      // return;
    }
    if (ptId && !directLink) {
      if (capMet === "true") {
        actions.setValue("comingFromSignup", true);
        actions.setValue("oauth", true);
        actions.setValue("hasMyChartAccount", true);
        auditTrail("Came from MyOchsner");
        auditTrail("Bad Attributes");
        navigate("/main", {
          state: { stepCount: PAGE_TITLES.ERROR_PATIENT_CAP.id },
        });
      } else {
        actions.setValue("patientId", ptId);
        actions.setValue("comingFromSignup", true);
        actions.setValue("oauth", true);
        actions.setValue("hasMyChartAccount", true);
        auditTrail("Came from MyOchsner");
        navigate("/main", {
          state: { stepCount: PAGE_TITLES.OAUTH_API_LOADER.id },
        });
      }
    } else if (ptId) {
      if (capMet === "true") {
        actions.setValue("comingFromSignup", true);
        actions.setValue("oauth", true);
        actions.setValue("hasMyChartAccount", true);
        auditTrail("Came from MyOchsner");
        auditTrail("Bad Attributes");
        navigate("/main", {
          state: { stepCount: PAGE_TITLES.ERROR_PATIENT_CAP.id },
        });
      } else {
        actions.setValue("patientId", ptId);
        actions.setValue("comingFromSignup", true);
        actions.setValue("oauth", true);
        actions.setValue("hasMyChartAccount", true);
        actions.setValue("directEnroll", true);
        navigate("/main", {
          state: { stepCount: PAGE_TITLES.OAUTH_API_LOADER.id },
        });
      }
    }
    UtmParameters(searchParams.toString());
  }, []);

  const getPageByStepNumber = (stepNumber: any) => {
    return Object.values(PAGE_TITLES).find((page) => page.id === stepNumber);
  };
  useEffect(() => {
    const id = searchParams.get("id");
    const stepNumberStr = searchParams.get("stepNumber");
    const ace = searchParams.get("ace");
    let stepNumber: number | null =
      stepNumberStr !== null ? parseInt(stepNumberStr, 10) : null;

    const pageToNavigate = getPageByStepNumber(stepNumber);

    if (stepNumber !== null && isNaN(stepNumber)) {
      stepNumber = null;
    }

    if (id && pageToNavigate) {
      actions.setValue("pageToNavigate", pageToNavigate.id);
      actions.setValue("id", id);
      if (ace) {
        actions.setValue("ACE", true);
      }
      actions.setValue("comingFromSignup", true);
      navigate("/main", {
        state: { stepCount: PAGE_TITLES.API_LOADER.id },
      });
    }
  }, []);

  const handleScrollToSignup = () => {
    console.log("scrolling");
    signupRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <MainContainer>
      <Header smoothScrolling={handleScrollToSignup} state={state} />
      <NewSignupContainer
        utmContent={utmContent}
        signupRef={signupRef}
        apiUrl={apiUrl}
        state={state}
        actions={actions}
      />
      <Programs />
      <VideoWithCheckItems />
      <JoinTheProgram smoothScrolling={handleScrollToSignup} />
      <CustomerReviewAndMetrics />
      <FAQ smoothScrolling={handleScrollToSignup} state={state} />
      <MoreInformationForm queryString={queryString} />
      <Footer />
    </MainContainer>
  );
};

export default Signup;
